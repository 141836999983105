import { type ReactNode } from 'react'

export const RenderIf = ({
	condition = false,
	children,
}: {
	condition: boolean
	children: ReactNode
}) => {
	if (condition) {
		return children
	} else {
		return null
	}
}
